.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
  direction: ltr;
}
.App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-bottom: 5vh;
  text-align: center;
}
