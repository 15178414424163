@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,700;1,400;1,700&display=swap');
* {
  font-family: 'Segoe UI';
  box-sizing: border-box;
}
body {
  color: rgba(0, 0, 0, 0.87);
  font-family: 'PT Sans', sans-serif;
  padding: 0;
  margin: 0;
}

:root {
  padding: 0;
  margin: 0;
}
