html,
body {
  padding: 0;
  margin: 0;
  direction: rtl;
}
* {
  box-sizing: border-box;
}
// @font-face {
//   font-family: helvetica;
//   src: url("/assets/font/helvetica-neue-w23-for-sky-bd.ttf");
// }
// @font-face {
//   font-family: helvetica-reg;
//   src: url("/assets/font/helvetica-neue-w23-for-sky-reg.ttf");
// }
// .helvetica-reg {
//   font-family: helvetica-reg !important;
// }
@media (min-width: 990px) {
  .only-mobile {
    display: none !important;
  }
  .only-desktop {
    display: inherit !important;
    &.block {
      display: block !important;
    }
    &.flex {
      display: flex !important;
    }
  }
}
.section.video .ant-modal-close-x path {
  fill: white !important;
}

@media (min-width: 990px) {
  .video-container {
    // width: 355px;
    width: 470px;
  }
  .center-content .video-container {
    width: 50vw;
    margin: 60px auto;
    background-size: cover;
    background-position: center;
    height: 500px;
    .play {
      margin-right: 22vw !important;
    }
  }
}

@media (min-width: 425px) {
  .center-content .video-container {
    .play {
      margin-right: 30vw;
    }
  }
}
// @media (max-width: 990px) {
//   .special-bg {
//     background-image: none !important;
//   }

//   section.section div.qiam-item {
//     padding-top: 20px;
//     &.inv {
//       svg path {
//         fill: #b23434;
//         stroke: white;
//         stroke-width: 1.3px;
//       }
//     }
//     svg {
//       width: 135px;
//       font-size: 100pt;

//       path {
//         fill: white;
//         stroke: #b23434;
//         stroke-width: 0.6px;
//       }
//     }
//   }
// }

@keyframes animate-play-icon {
  from {
    box-shadow: 0px 0px 0px 1px #d9e8ff;
  }
  to {
    box-shadow: 0px 0px 0px 20px #f42b5014;
  }
}

* {
  font-family: "Cairo" !important;
  outline: none;
}
.btn {
  width: 94px;
  height: 48px !important;
  border: solid 2px #dfdfdf !important;
  // font-family: helvetica !important;
  font-weight: bold;
  border-radius: 7px !important;
  &.ant-btn-primary {
    color: white;
  }
}
svg.icon {
  width: 24px;
  height: 24px;
}
.right {
  text-align: right;
}
a svg:not(.white) {
  color: black;
}
a.white svg {
  color: white;
}
img.full-width {
  width: 100%;
}
p.small {
  font-size: 12px;
  line-height: 20px;
}
p {
  line-height: 24px !important;
  margin-bottom: 3px !important;
}
input.outlined {
  border: solid 1px white;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: transparent;
  border-left: none;
  // margin-left: -3px;
  // padding-left: 11px;
  height: 40px;
}
.description {
  font-size: 14px;
  line-height: 19px;
  color: #4a4a4a;
  text-align: right;
  // font-family: helvetica-reg !important;
}
hr.red {
  height: 2px;
  width: 205px;
  background-color: #b23434;
  margin-right: 0px;
}
.big-title {
  color: #002366;
  font-size: 1.7em;
  font-weight: bold;
  &.u {
    line-height: 71px;
    &.sm {
      line-height: 60px;
      &::after {
        top: 0px !important;
        margin-bottom: 23px !important;
      }
    }
    &.center::after {
      content: "";
      height: 3px;
      width: 205px;
      background-color: #b23434;
      position: relative;
      top: 13px;
      left: 0px;
      display: block;
      margin: auto;
      border-radius: 37px;
      margin-bottom: 13px;
    }
    &.right::after {
      content: "";
      height: 3px;
      width: 205px;
      background-color: #b23434;
      position: relative;
      top: 5px;
      left: 0px;
      display: block;
      margin: unset;
      border-radius: 37px;
      margin-bottom: 13px;
    }
  }
}
.center {
  text-align: center;
  justify-content: center;
}
section.section {
  padding: 80px;
  &.full-h {
    min-height: 100vh;
    // padding-top: 26vh;
    padding-top: 10vh;
  }
  &.blue {
    background-color: #d9e8ff;
  }
  .card-book-item .card-book-footer .price {
    font-size: 109%;
  }
  &.video {
    background-image: url(assets/img/v02-layers-14.png);
    align-items: center;
    justify-content: center;
    display: flex;

    .big-title {
      color: white !important;
    }
    .description {
      line-height: 43px !important;
      color: white !important;
    }
  }
  .section-content {
    padding-top: 52px;
  }
  .qiam-item {
    padding-top: 20px;
    &.inv {
      svg path {
        fill: white;
        stroke: white;
        stroke-width: 0px;
      }
    }
    svg {
      width: 90px;
      font-size: 68pt;
      // fill: #b23334;
      margin-bottom: 16px;
      path {
        fill: white;
        stroke: white;
        stroke-width: 0px;
      }
    }
    p {
      color: black !important;
      font-size: 1.2em;
      max-width: 24ch;
      margin: auto;
      margin-block-start: 17px;
    }
    p:not(.title-sm) {
      // font-family: helvetica-reg !important;
    }
  }
  .service-item {
    // height: 163px;
    min-height: 210px;
    max-width: 182px;
    // display: flex;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all ease 0.7s;
    // margin-left: 40px;

    justify-content: center;
    display: flex;
    position: relative;
    // top: 55px;
    margin-bottom: 70px;
    margin-top: 55px;
    margin-right: auto;
    margin-left: auto;
    &:hover {
      // box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.26);
      transform: translateY(-5px);
      transform: scale(1.4);
      max-width: 200px;
      // height: 100%;
      .cover {
        transform: scale(0.7);
      }
      .title {
        transform: scale(0.9);
        // margin-bottom: 0px;
      }
      .content {
        top: 28px;
        padding-bottom: 0px;
      }
      .content p {
        // display: block;
        transform: scale(1);
        // opacity: 1;
        font-size: 10px;
        margin-bottom: 2px !important;
      }
    }
    .cover {
      transition: all ease 0.5s;
      background-size: cover;
      height: 135px;
      width: 135px;
      border-radius: 102px;
      border: solid 3px white;
      margin: auto;
      position: absolute;
      top: -75px;
    }
    .content {
      padding: 19px;
      position: relative;
      top: 57px;
      // margin-bottom: 28px;
      span {
        font-size: 1.3em;
        color: black !important;
        // font-family: helvetica-reg !important;
        margin-bottom: 17px;
        display: block;
        padding: 0px !important;
        line-height: 29px;
      }
    }

    p {
      font-size: 5px;
      color: #545454;
      text-align: right;
      // font-family: "helvetica-reg" !important;
      transition: all ease 0s;
      // display: none;
      // height: 0px;
      transform: scale(0);
      line-height: 19px !important;
      // opacity: 0;
    }
  }
}
.title-sm {
  color: #002366 !important;
  font-size: 12px;
  line-height: 21px !important;
  // font-weight: bold;
  margin-block-start: 17px;
  &.red {
    color: #b23434 !important;
    font-size: 24px;
    line-height: 31px !important;
    text-align: center;
    margin-bottom: 17px;
  }
}
.title {
  color: #002366 !important;
  font-size: 18px;
  line-height: 19px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin: auto;
}
.title-md {
  color: #002366 !important;
  font-size: 24px;
  font-weight: bold;
  // margin-block-start: 19px;
  text-align: right;
  margin-top: 15px;
  margin-bottom: 9px !important;
  text-align: right;
}
img.ronded {
  width: 147px;
  height: 147px;
  border-radius: 50%;
}
.flex-end {
  justify-content: flex-end;
}
.slider-item {
  max-height: 920px;
}
.carousel.slide {
  max-height: 100vh;
  overflow: hidden;
}
.rec.rec-carousel > span:first-child {
  // &:nth-child(1){
  //   left: 0px;
  // }
  // &:nth-child(2){
  //   right: 0px;
  // }
  position: absolute;
  z-index: 1;
  left: 0px !important;
}
.rec.rec-carousel > span {
  // &:nth-child(1){
  //   left: 0px;
  // }
  // &:nth-child(2){
  //   right: 0px;
  // }
  position: absolute;
  z-index: 1;
  right: 0px;
}
/* square buttons */
.rec.rec-arrow {
  border-radius: 0;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

.arrow-slider {
  border: solid;
  stroke: #dbdbdb;
  border-color: #dbdbdb;
  border-width: 4px;
  stroke-width: 2px;
  /* color: white; */
  border-radius: 47pt;
  padding: 6px 9px 6px 6px;
  margin-top: 2em;
}
.carousel-item img {
  max-width: 100%;
  height: 100%;
}
.carousel-item {
  height: 100%;
}
.carousel.slide {
  height: calc(100vh - 13px);
  .carousel-inner {
    height: 100%;
  }
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  border: solid 4px white;
  padding: 18px;
  background-size: 64% !important;
  border-radius: 50%;
  background-position-x: 41% !important;
}
.carousel-indicators li {
  width: 11px !important;
  height: 11px !important;
  border-radius: 50%;
  margin: auto 4px;
}
.carousel-indicators .active {
  background-color: #002366;
}
.articles {
  padding-bottom: 90px !important;
  &.content {
    display: flex;
  }
  .section-head {
    padding-right: 2em;
    // margin-top: 60px;
  }
  .rec-carousel-item {
    .article-item,
    .card-course-item {
      // max-width: 378px;
      // width: 367px;
      width: 89%;
    }
  }
}
.members {
  background-image: url(assets/img/v02-layers-20.png);
  background-color: white;
  background-position: revert;
  background-size: cover;
  padding-bottom: 75px !important;
}
.services {
  background-image: url(assets/img/v02-layers-20.png);
  background-position: center;
  background-size: cover;
}
.carousel-item img {
  width: 100%;
}
.centers {
  background-image: url(assets/img/v02-layers-14.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(assets/img/image-slider.png);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  p {
    color: #9a9a9a;
  }
}
.nav-arrows {
  // padding-left: 6em;
  // padding-bottom: 1em;
  // position: absolute;
  // left: 32px;
  // margin-top: -63px;
  text-align: left;
  margin-bottom: 14px;
}
main > .header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(assets/img/image-slider.png);
  //  url(assets/img/v02-layers-14.png);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 360px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.sm {
    height: 153px;
  }
  &.bg-blue {
    background-image: url(assets/img/v02-layers-14.png) !important;
    background-size: contain;
    background-position: bottom;
  }
  img {
    height: 97px;
    margin: auto;
    margin-bottom: 45px;
  }
  h1 {
    font-size: 2.1em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
a.def {
  color: #545454;
  display: block;
  text-align: right;
  // font-family: helvetica-reg !important;
  &.sm {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
.part {
  margin-bottom: 65px;
}
ul.last-dawarat {
  list-style: none;
  padding: 0px;
  width: 110%;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      font-size: 22px;
      color: #545454 !important;
      text-align: right;
      // font-family: "helvetica-reg" !important;
      padding-right: 17px;
    }
  }
}
.content.centred {
  // max-width: 1300px;
  margin: auto;
  width: 75vw;
}
.ant-breadcrumb.links {
  display: flex;
  padding-top: 13px;
  font-size: 19px;
}
.content.list-centers {
  .center-item {
    margin: 32px 0em !important;
    .content {
      text-align: right;
      a:hover {
        text-decoration: none;
      }
    }
    .title-md {
      font-size: 21px !important;
      margin-top: 7px;
    }
    .description {
      font-size: 13px !important;
    }
  }
}
.inscription-main {
  &:not(.full) {
    .content {
      padding: 64px 27vw;
      padding-right: 10vw;
    }
  }
  .content {
    text-align: right;

    .form {
      // max-width: 81%;
      // margin: auto;
      margin-bottom: 65px;
    }
    &.register-choice {
      display: flex;
      justify-content: center;
      padding: 64px !important;
      gap: 10px;

      .box {
        padding: 80px;
        color: #545454;
        border: solid 1px #d9e8ff;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;
        p {
          margin-top: 20px;
        }
        svg {
          font-size: 46pt;
          fill: #545454;
        }
        &:hover {
          color: #002366;
          border: solid 1px #1974ff;
          svg {
            fill: #002366;
          }
        }
      }
    }
  }
  li,
  label {
    color: #4a4a4a;
    // font-family: helvetica-reg !important;
    line-height: 2;
    font-size: 15px;
    margin-bottom: 0px !important;
    display: block;
    margin: 0;
  }
  button.ant-btn-primary {
    background: #002366 !important;
    padding: 5pt 52pt !important;
    height: 52px !important;
    font-size: 18px !important;
    border-radius: 8px !important;
    border: solid 1px #002366 !important;
    transition: all ease 0.3s;
    &:hover {
      background-color: white !important;
      color: #002366 !important;
      border: solid 1px #002366 !important;
      // box-shadow: 1px 4px 10px #00246625;
      // transform: translateY(-6px);
    }
  }
  input.ant-input {
    height: 40px !important;
    border: solid 2px #d4def1 !important;
    border-radius: 4px !important;
    font-size: 1em !important;
    // font-family: helvetica-reg !important;
    margin-bottom: 19px !important;
  }
  textarea.ant-input {
    border: solid 2px #d4def1 !important;
    border-radius: 4px !important;
    font-size: 1em !important;
    // font-family: helvetica-reg !important;
    margin-bottom: 19px !important;
  }
  .ant-checkbox:not(.ant-checkbox-checked) + span {
    // font-family: helvetica-reg !important;
  }
}
.img {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}

@media (min-width: 1900px) {
  section.full-h {
    min-height: auto !important;
    padding-top: 80px !important;
  }
  section.section {
    padding: 80px 20vw;
  }
}

@media (max-width: 1024px) {
  .articles article.article-item {
    width: 30%;
  }
}

.ant-rate-star:not(:last-child) {
  margin-right: 0px !important;
  max-width: 19px;
}
section.gray {
  background: #e9e9e9;
}
a:hover {
  color: #b23434 !important;
}

a.btn-more {
  margin: auto;
  display: block;
  text-align: center;
  border: solid 1px #d9e8ff;
  max-width: max-content;
  padding: 10pt 40pt;
  border-radius: 6px;
  color: #002366;
  text-decoration: none;
  background-color: white;
  transform: translateY(35px);
  transition: all ease 0.3s;
  box-shadow: 1px 1px 3px #00246617;
  margin-bottom: 37px;
  &:hover {
    text-decoration: none;
    box-shadow: 2px 5px 10px #0024664d;
  }
  &.no-border {
    border: none;
  }
  &.no-deplacing {
    transform: translateY(0px);
  }
}
.special-bg {
  // background-image: url(assets/img/Path661.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}
.white-btn {
  font-size: 1em;
  border: none;
  background: white;
  padding: 1em;
  border-radius: 5pt;
  color: #002366;
  // font-family: "helvetica" !important;
  transition: all ease 0.5s;
  box-shadow: inset 0px 0px 0px 0px #002366;
  outline: none;
  &:hover {
    // border: solid 1px #002366;
    box-shadow: inset 0px 0px 0px 102px #002366;
    color: white;
  }
}
.title1 {
  font-size: 2em;
  text-align: center;
  color: black;
}
button.ant-btn-primary {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #002366;
  border-radius: 5pt;
  border: none;
  padding: 7px 15px;
  // height: unset !important;
  &.light span {
    // font-family: Helvetica-reg !important;
    border-radius: 6px !important;
    font-size: 1.3em;
  }
}
.center-main {
  .center-header {
    height: 100vh;
    //  background-image: linear-gradient(transparent 58%, white 100%),
    //  url(/assets/img/image-slider.png);
    background-size: cover;
    background-position: center;
  }
  .center-content {
    text-align: center;
    margin-top: -200px;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    .logo-center {
      height: 280px;
      width: 280px;
      border-radius: 12px;
      background-color: white;
      // background-image: url(/assets/img/ONTWNC1-11.png);
      background-size: contain;
      background-repeat: no-repeat;
      margin: auto;
      box-shadow: 0 6px 99px 0 rgba(0, 0, 0, 0.16);
    }
    .title {
      font-size: 2.3em;
      margin-top: 15pt;
      margin-bottom: 2em;
    }
    button.light {
      height: unset;
    }
    .description {
      font-size: 18px !important;
      line-height: 28px !important;
      margin-bottom: 1em !important;
    }
    .card-gray {
      background-color: #f5f5f5;
      padding: 30px 15px;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      border-radius: 12px;
      align-items: center;
      .logo-center {
        height: 130px;
        width: 130px;
        // margin-left: 90px;
        margin-bottom: 20px;
      }
      .description {
        margin-bottom: 0em !important;
      }
    }
    .card-blue {
      background-color: #d9e8ff;
      border-radius: 12px;
      padding: 31px 28px 13px 12px;
      text-align: right;
      margin: 60px 0px;
      margin-top: 130px;
      .title {
        font-size: 1.7em;
        // font-family: "helvetica-reg" !important;
        margin-bottom: 1px;
        margin-top: 1px;
        padding-top: 0px !important;
      }
      button.light {
        padding: 7px 35px;
      }
    }
    .card-course-description {
      // font-family: Helvetica-reg !important;
      font-size: 14px !important;
      line-height: 1.33;
      text-align: right;
      color: #707070;
    }
    .nav-arrows {
      // padding-left: 2em;
    }
    .no-deplacing {
      margin: 2.2em auto;
      border: solid 1px #d9e8ff;
      box-shadow: none;
    }
    .galery-center {
      margin-top: 30px;
      margin-bottom: 60px;
      display: block;
    }
    .translate-top {
      transform: translateY(54px);
    }
    .space-bottom {
      margin-bottom: 3em;
    }
    .space-top {
      margin-top: 6em;
    }
    .social-icons {
      text-align: right;
    }
    div#ReactGridGallery {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.map-container {
  margin-top: 20px;
  border: solid 1px #d9e8ff;
  padding: 12px;
  table {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    td {
      color: #545454;
      // font-family: Helvetica-reg !important;
      font-size: 1em !important;
      border-top: solid 1px #d9e8ff;
      border-bottom: solid 1px #d9e8ff;
      height: 40px;
      padding-right: 10px;
    }
  }
}
.left {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  left: 0 !important;
  right: unset !important;
}
.upload-container {
  & > span {
    display: flex;
  }
}
.small-gray {
  font-size: 1em !important;
  line-height: 2.79;
  color: #545454 !important;
  display: block;
  // font-family: Helvetica-reg !important;
  &:hover {
    color: #002366 !important;
    text-decoration: none !important;
  }
}
.xl-title {
  font-size: 50px !important;
  color: white;
}
.b-title {
  text-align: right;
  margin-bottom: 25px !important;
  font-weight: bold;
  font-size: 24px;
}
main.login .ant-row {
  // background-image: url(/assets/img/v02-layers-14.png);
  background-size: cover;
}
.login-container {
  background-color: white;
  border: solid 1px #d9e8ff;
  border-radius: 6px;
  padding: 2em;
  margin: 4em;
  // margin-top: 1em;
  text-align: right;
  input {
    margin-bottom: 2em;
  }
  .formfooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: black;
      font-size: 10pt;
      svg {
        font-size: 12pt;
      }
    }
  }
  button {
    min-width: 8em;
    padding: 8px 6em;
    height: unset;
  }
}
.file-item {
  margin: 1em;
  & > span {
    display: flex;
    gap: 12px;
  }

  .file-name {
    color: #002366;
  }
  .file-status {
    color: #4a4a4a;
    // font-family: helvetica-reg !important;
  }
}
.ant-table-thead > tr > th {
  text-align: center !important;
}
span.ant-table-column-sorter.ant-table-column-sorter-full {
  margin-right: 1em;
}
.ant-table table {
  text-align: right !important;
}
.ant-btn.primary-btn,
.ant-btn.primary-btn:focus,
.ant-btn.primary-btn:hover {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #002566;
  color: white;
  border: none;
  margin-bottom: 1em;
}
.ant-modal-content {
  text-align: right;
  .ant-modal-close {
    right: unset;
    left: 0;
  }
}
.div-empty {
  width: 100vw;
  text-align: center;
  margin: 5em;
  color: grey;
}
.ticket-row:hover {
  cursor: pointer;
}
input.rw-input {
  min-width: 310px;
  height: 64px;
  margin: 3px 0 22px 10px;
  padding: 20px;
  width: 42vw;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: none;
  direction: rtl;
  font-size: 20px;
  &::placeholder {
    color: #c9c9c9;
    // font-family: helvetica-reg !important;
    font-size: 20px;
  }
}
textarea.rw-input {
  min-width: 310px;
  // height: 64px;
  margin: 3px 0 22px 10px;
  padding: 20px;
  width: 42vw;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: none;
  direction: rtl;
  font-size: 20px;
  &::placeholder {
    color: #c9c9c9;
    // font-family: helvetica-reg !important;
    font-size: 20px;
  }
}
.member-card {
  text-align: center;
  justify-content: center;
  strong {
    color: #002366;
    font-size: 1.23em;
    line-height: 44px;
    display: block !important;
    margin-top: 17px;
  }
  p.gray {
    font-size: 1em;
    line-height: 16px;
    color: #909090;
    // font-family: helvetica-reg !important;
  }
  .socials {
    display: flex;
    margin-top: 11px;
    justify-content: center;
  }
  .social-btn {
    display: flex;
    align-items: end;
    justify-content: center;
    margin-left: 5px;
    width: 50px;
    // height: 31px;
    padding: 10px 10px 15px 10px;
    border-radius: 6px;
    background-color: #202020;
    color: white;
    // align-items: center;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    &:hover {
      background: #002366;
      transform: translateY(-5px);
    }
    svg {
      font-size: 11pt;
    }
  }
}

.video-container {
  // width: 353px;
  height: 380px;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 20px;
  .play {
    position: absolute;
    color: white;
    border: solid 1px;
    width: 70px;
    height: 70px;
    padding: 21px;
    border-radius: 50%;
    margin-right: 12em;
    animation: animate-play-icon 1.4s infinite;
    margin-right: 25vw;
    // display: flex;
    // align-items: center;
  }
}
.card-course-item {
  background: white;
  border: solid 1px #d9e8ff;
  display: flex;
  flex-flow: column;
  // min-height: 345px;
  min-height: 320px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  transition: all ease 0.3s;
  &.activity {
    margin: 0px 8px;
    .card-course-content {
      margin-top: 0px;
      .title-card-course {
        color: #002366;
        text-align: right;
      }
    }
    .card-course-footer {
      text-align: left !important;
      display: block;
      color: #002366;
      direction: rtl;
      &::before {
        content: "";
        display: none;
      }
    }
  }
  &.list {
    direction: rtl !important;
    display: flex;
    width: 100%;
    max-width: 100% !important;
    flex-flow: unset;
    min-height: unset !important;
    .card-course-cover {
      max-width: 308px;
      height: unset;
      .course-price {
        border-radius: 17px 0px 0px 17px;
      }
    }
    .course-price {
      margin-top: -25px;
    }
    .avatar {
      top: -42px;
      position: absolute;
      right: -68px;
    }
    .card-course-content {
      margin-top: 40pt;
      margin-right: 40px;
    }
    .author {
      text-align: right;
    }
    .card-course-footer {
      justify-content: end;
      &::before {
        content: "";
        display: none;
      }
    }
    .title-card-course {
      text-align: right;
      &::after {
        content: "";
        background: #d9e8ff;
        width: 200px;
        height: 1px;
        top: 8px;
        position: relative;
        display: block;
      }
    }
    .card-course-description {
      font-size: 18px;
      line-height: 1.06;
      text-align: right;
      // font-family: helvetica-reg !important;
      color: #545454;
      margin-top: 20px;
    }
    .card-course-footer {
      direction: initial !important;
      display: flex;
      padding: 17px;
      margin: auto;
      color: #00000099;
      align-items: center;
      position: absolute;
      left: 0px;
      width: 27%;
      // left: 64px;
      // width: 25%;
    }
    &:hover {
      // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
      transform: none;
      background-color: whitesmoke;
    }
  }
  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  }
  .card-course-cover {
    width: 100%;
    height: 142px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    // justify-content: center;
    .course-price {
      // position: absolute;
      top: 35px;
      transform: translateY(-35px);
      background-color: #002366;
      color: white;
      padding: 2px 21px;
      border-radius: 0px 17px 17px 0px;
      text-align: left;
      &:not(.free) {
        font-family: arial !important;
      }
      &.free {
        background-color: #48b811 !important;
      }
    }
  }
  .avatar {
    width: 56px;
    height: 56px;
    background-position: center;
    background-size: cover;
    border: solid 2px #ffffff;
    border-radius: 50%;
    top: -60px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    margin: auto;
    position: relative;
  }
  .title-card-course {
    font-size: 1.2em;
    color: black;
    text-align: center;
    line-height: 1.4 !important;
    margin-top: 5px;
  }
  .author {
    font-size: 1em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: center;
    color: #002366;
    margin-top: -40px;
  }
  .card-course-content {
    margin: auto;
    padding: 8pt;
    text-align: center;
    margin-top: 20pt;

    position: relative;
    span {
      display: block;
    }
    .author {
      color: #002366;
      font-size: 1em;
      font-weight: lighter;
      // font-family: "helvetica-reg" !important;
      top: -10px;
      position: relative;
      &.alone {
        margin-top: 29px;
      }
    }
    .date {
      color: #707070;
      font-size: 16px;
      // font-family: helvetica-reg !important;
      font-weight: 400;
    }
  }
  &:not(.list):not(.landing) {
    .card-course-footer:not(.act) {
      position: absolute;
      width: 90%;
      bottom: 22px;
    }
  }
  .card-course-footer {
    display: flex;
    justify-content: space-around;
    padding: 11px;
    margin: 0px auto;
    color: #00000099;
    align-items: center;
    width: 100%;
    &::before {
      content: "";
      background: #d9e8ff;
      width: 91%;
      height: 1px;
      top: -9px;
      position: relative;
    }
    span {
      // font-family: helvetica-reg !important;
      margin-right: 3px;
      font-size: 0.8em;
    }
    svg {
      font-size: 1em;
    }
  }
  .title-sm.red {
    margin-bottom: 23px !important;
  }
}

.soon span {
  width: 9vw;
  background: #d9e8ff;
  padding: 5px;
  color: #678bd1;
  font-size: 18px;
  display: block;
  text-align: center;
  border-radius: 21px;
  font-weight: bold;
  line-height: 1;
}
.list-courses .card-course-item:not(.list) {
  height: 375px;
  &:hover {
    transform: none !important;
  }
}
@media (min-width: 768px) {
  .center-main {
    .card-gray {
      flex-direction: row !important;
      .logo-center {
        margin-left: 30px !important;
      }
    }
  }
}
// @media (max-width: 380px) {
//   .list-courses .card-course-item:not(.list) {
//     height: unset;
//   }
// }
// @media (max-width: 900px) {
//   .list .card-course-footer {
//     left: 0px !important;
//   }
//   .card-course-item:not(.list) .card-course-footer {
//     position: static !important;
//   }
//   .soon span {
//     width: 18vw;
//   }
// }

// @media (min-width: 906px) and (max-width: 1188px) {
//   // list carousel on landing page
//   .rec-carousel-item .card-course-item {
//     min-height: 365px;
//   }

//   //
// }
// @media (min-width: 1200px) {
//   .list-courses .card-course-item:not(.list) {
//     height: 320px;
//   }
// }
.nav-arrows {
  // padding-left: 6em;
  // padding-bottom: 1em;
  // position: absolute;
  // left: 32px;
  // margin-top: -63px;
  text-align: left;
  margin-bottom: 14px;
}
.list-members {
  // padding-block-start: 60px;
  // width: 100vw;
}
.bi {
  font-size: 17pt;
  border: solid 3px #dbdbdb;
  color: #dbdbdb;
  padding: 4pt;
  height: 43px;
  width: 43px;
  border-radius: 50%;
}
.anticon {
  font-size: 60px;
}
